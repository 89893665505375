<!--
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2024-02-02 14:40:03
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-18 14:57:31
-->
<template>
  <el-dialog
    :visible.sync="editFileShow"
    :before-close="cancel"
    :close-on-click-modal="false"
    title="编辑文件"
    width="560px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="80px"
      @keyup.enter.native="submitHandle()"
    >
      <el-form-item label="所属分组" prop="groupId">
        <GlobalTreeSelect
          style="width: 400px"
          v-model="dataForm.groupId"
          filterable
          :nodeKey="nodeKey"
          :treeProps="defaultProps"
          :data="treeData"

         >
        </GlobalTreeSelect>
      </el-form-item>
      <el-form-item label="文件名称" prop="fileName">
        <el-input
        style="width: 400px"
          v-model="dataForm.fileName"
          placeholder="请输入文件名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="带教老师" >
        <el-select style="width: 200px" v-model="dataForm.lecturerTeamId"  @change="changelecturerTeam" placeholder="请选择">
            <el-option
              v-for="item in teacherTeamList"
              :key="item.id"
              :label="item.lecturerTeam"
              :value="item.id"
              >
            </el-option>
          </el-select>
          <el-select  style="width: 200px" v-model="dataForm.teacherUserIds" placeholder="请选择">
            <el-option
              v-for="item in teachUserList"
              :key="item.employeeId"
              :label="item.employeeName"
              :value="item.employeeId">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="文件" >
        <el-upload
          name="files"
          :headers="uploadHeader"
          :action="uploadAction"
          :on-success="_handleSuccess"
          :on-change="onChange"
          :limit="1"
          :file-list="fileLists"
          accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG, .doc,.docx, .xls, .xlsx, .ppt, .pdf, .mp4,.pptx"
          >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submitHandle()">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
import GlobalTreeSelect from '@/components/globalTreeSelect/index.vue'
import { fileEditApi } from '@/api/resource'
import {
  getTeachUserListApi,
  getTeachTeamListApi
} from '@/api/settings'
import { getCookie } from '@/utils/util'
import fileType from '@/mixins/fileType'
export default {
  mixins: [fileType],
  components: { GlobalTreeSelect },
  props: {
    editFileShow: {
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array,
      default: () => []
    },
    defaultProps: {
      type: Object,
      default: () => {}
    },
    nodeKey: {
      type: String,
      default: 'id'
    },
    currentFile: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      uploadHeader: {
        token: getCookie('token')
      },
      uploadAction:
        process.env.VUE_APP_BASE_API_IMAGE + '/oss-service/ossObject/upload/big/file?toPdfFlag=True', // 上传附件 链接
      dataForm: {
        groupId: '',
        fileName: '',
        lecturerTeamId: '', // 讲师团队id
        teacherUserIds: '', // 带教老师ID
        teacherUserNames: '' // 带教老师名称
      },
      fileLists: [],
      teacherTeamList: [], // 讲师团队
      teachUserList: [], // 讲师列表
      dataRule: {
        groupId: [
          {
            required: true,
            message: '请输入所属分组',
            trigger: 'change'
          }
        ],
        fileName: [
          {
            required: true,
            message: '请输入文件名称',
            trigger: 'change'
          }
        ]
      }
    }
  },
  created () {
    this.dataForm = JSON.parse(JSON.stringify(this.currentFile))
    this.getTeachTeamList()
    this.getTeachUserList(this.currentFile.lecturerTeamId)
  },
  methods: {
    changelecturerTeam (value) {
      this.dataForm.teacherUserIds = ''
      this.dataForm.teacherUserNames = ""
      this.getTeachUserList(value)
    },
    getTeachTeamList () {
      getTeachTeamListApi().then(res => {
        if (res.code === 200) {
          this.teacherTeamList = res.data
        }
      })
    },
    getTeachUserList (lecturerTeamId) {
      getTeachUserListApi({
        lecturerTeamId
      }).then(res => {
        if (res.code === 200) {
          this.teachUserList = res.object.results
          const obj = res.object.results.find(item => {
            return item.employeeId == this.currentFile.teacherUserIds
          })
          if (obj) {
            this.dataForm.teacherUserIds = obj.employeeId
          } else {
            this.dataForm.teacherUserIds = this.dataForm.teacherUserNames
          }
        }
      })
    },
    cancel () {
      // 取消
      this.$parent.editFileShow = false
    },
    submitHandle () {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.teachUserList.forEach(item => {
            if (this.dataForm.teacherUserIds === item.employeeId) {
              this.dataForm.teacherUserNames = item.employeeName
            }
          })
          const fileList = []
          this.fileLists.forEach(item => {
            if (item.response) {
              fileList.push({
                fileType: this.matchType(item.name),
                size: item.size,
                fileDuration: item.response.data && item.response.data[0].fileDuration,
                sourceFileUrl: item.response.data[0].url,
                percentage: item.percentage,
                fileSuffix: item.response.data[0].url.substring(item.response.data[0].url.lastIndexOf('.') + 1)
              })
            }
          })
          fileEditApi({
            ...this.dataForm,
            id: this.currentFile.id,
            ...fileList[0]
          }).then(res => {
            if (res.code === 200) {
              this.$parent.query()
              this.$parent.editFileShow = false
            }
          })
        }
      })
    },
    onChange (file, fileList) {
      if (this.fileLists.length > 1) {
        this.$message.info('只能上传一个文件！')
        return false
      }
    },
    _handleSuccess (response, file, fileList) {
      if (response.code == 200) {
        const arr = []
        fileList.forEach(item => {
          if (item.response.code === 200) {
            arr.push(item)
          }
        })
        this.fileLists = arr
        this.dataForm.fileName = arr[0].name
      } else {
        const arr = []
        fileList.forEach(item => {
          if (item.response.code === 200) {
            arr.push(item)
          }
        })
        this.fileLists = arr
        this.dataForm.fileName = arr[0].name
        this.$message.error(response.message)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-upload--text {
  width: auto;
    height: auto;
    border: none;
}
</style>
